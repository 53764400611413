//-----------------------------------------------------------------------------/
//  Settings
//
//  @Colors..................Global Colors
//  @Typography..............Typography Styles
//  @Spacing.................Horiontal & Vertical Spacing
//
//--------------------------------------------------*/



// @Colors
// ===========================================


// B & W
$white:                         #fff;
$black:                         #010101;


// blue

$blue-1:                        #3D70DB;
$blue-light-1:                  #799EE6;
$blue-light-2:                  #4E7BE4;

// Grays
$grey:                          #ccc;
$grey-light:                    lighten($grey, 10%);
$grey-lighter:                  lighten($grey, 20%);
$grey-dark:                     darken($grey, 10%);
$grey-darker:                   darken($grey, 20%);


// Brand Colors
$brand-color-1:                 $white;
$brand-color-2:                 $black;
$brand-color-2-light:           lighten($brand-color-2, 10%);
$brand-color-2-dark:            darken($brand-color-2,  10%);

// Links
// $link-color:                    $brand-color-1;
// $link-hover-color:              darken($link-color, 15%);


// @Typography
// ===========================================

// Font Stacks
$font-helvetica:                Helvetica, Arial, sans-serif;
$font-helvetica-light:          Helvetica Light, Arial, sans-serif;

$font-poppins-light:            'Poppins Light', Arial, sans-serif;
$font-poppins-medium:           'Poppins Medium', Arial, sans-serif;
$font-poppins-regular:          'Poppins Regular', Arial, sans-serif;
$font-poppins-semibold:         'Poppins Semibold', Arial, sans-serif;
$font-poppins-bold:             'Poppins Bold', Arial, sans-serif;

$font-butler-ultra-light:            'Butler UltraLight', Arial, sans-serif;
$font-butler-light:                  'Butler Light', Arial, sans-serif;
$font-butler-medium:                 'Butler Medium', Arial, sans-serif;
$font-butler-regular:                'Butler Regular', Arial, sans-serif;
$font-butler-bold:                   'Butler Bold', Arial, sans-serif;
$font-butler-extra-bold:             'Butler ExtraBold', Arial, sans-serif;
$font-butler-black:                  'Butler Black', Arial, sans-serif;

$font-gotham-thin:                   'Gotham Thin', Arial, sans-serif;
$font-gotham-x-light:                'Gotham XLight', Arial, sans-serif;
$font-gotham-light:                  'Gotham Light', Arial, sans-serif;
$font-gotham-book:                   'Gotham Book', Arial, sans-serif;
$font-gotham-medium:                 'Gotham Medium', Arial, sans-serif;
$font-gotham-bold:                   'Gotham Bold', Arial, sans-serif;
$font-gotham-black:                  'Gotham Black', Arial, sans-serif;
$font-gotham-ultra:                  'Gotham Ultra', Arial, sans-serif;


// Copy Font
// $font-family-main-ultra-light:            $font-butler-ultra-light;
// $font-family-main-light:                  $font-butler-light;
// $font-family-main-medium:                 $font-butler-medium;
// $font-family-main-regular:                $font-butler-regular;
// $font-family-main-bold:                   $font-butler-bold;
// $font-family-main-extra-bold:             $font-butler-extra-bold;
// $font-family-main-black:                  $font-butler-black;


$font-family-main-thin:              $font-gotham-thin;
$font-family-main-x-light:           $font-gotham-x-light;
$font-family-main-light:             $font-gotham-light;
$font-family-main-book:              $font-gotham-book;
$font-family-main-medium:            $font-gotham-medium;
$font-family-main-bold:              $font-gotham-bold;
$font-family-main-black:             $font-gotham-black;
$font-family-main-ultra:             $font-gotham-ultra;


// Font Sizes
$font-size-smaller:              .750rem; // 12px
$font-size-small:                .875rem; // 14px
$font-size-default:                 1rem; // 16px
$font-size-base:                1.125rem; // 18px
$font-size-medium:              1.250rem; // 20px
$font-size-large:               1.563rem; // 25px
$font-size-larger:              2.188rem; // 35px
$font-size-extra-large:         4.375rem; // 70px



// @Spacing
// ===========================================


$parallax-perspective : 1 !default;
$parallax-element     : "body" !default;
$parallax-ios         : true !default;
