.c-slider {
  background-color: $blue-light-2;
  border-bottom: 2px solid $blue-light-1;
  .slick-dots {
    bottom: -26px;
    left: 0;
  }
  @media (min-width: $screen-sm-min) {
    padding: 1rem;
    margin-bottom: 5rem;
    border-top: 2px solid $blue-light-1;
    .slick-dots {
      bottom: 35px;
    }
  }
}

  .c-slider-inner {
    @media (min-width: $screen-sm-min) {
      border: 2px solid $blue-light-1;
    }
  }
