.c-privacy-policy {

}

.iubenda_legal_document {
    h1, h2, h3, h4, h5, h6 {
        margin-top: 2rem;
        font-family: $font-family-main-bold;
        font-weight: normal;
        letter-spacing: 0;
    }
    h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 2.5rem;
    }
    h3 {
        font-size: 1.6rem;
    }
    h4 {
        font-size: 1.2rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 1rem;
    }
    p {
        p + {

        }
    }
}