.c-faq {

}

  .c-faq__question {
    margin: 2rem 0 0.5rem;
    font-family: $font-family-main-bold;
  }

  .c-faq__answer {
    font-size: $font-size-smaller;
    line-height: 1.6;
  }
