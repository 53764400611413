.o-label {

}

.o-label-pill {
  border: 2px solid $white;
  border-radius: 100px;
  padding: 0.7rem 1rem;
  font-size: $font-size-small;
  line-height: 1;
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-default;
  }
}
