.o-subscribe-widget {
    margin-top: 1rem;
}

    .o-subscribe-widget__form {

    }

    .sg-response {
        margin-bottom: 1rem;
        font-weight: bold;
    }