.o-subscribe__form {
  display: flex;
  margin-top: 1rem;
}

  .o-subscribe__input {
        background-color: transparent;
        height: 50px;
        vertical-align: middle;
        transition: all 0.25s ease-in-out;
      }

        .o-subscribe__input--email {
          width: 65%;
          padding-left: 1.5rem;
          font-family: $font-family-main-medium;
          font-size: $font-size-base;
          color: $white;
          border: 2px solid $white;
          border-radius: 100px 0 0 100px;
          // &:focus {
          //   border-color: $blue-light-1;
          // }
          &::placeholder {
            color: $white;
          }
          @media (min-width: $screen-md-min) {
            font-size: $font-size-default;
          }
        }

        .o-subscribe__input--submit {
          width: calc(35% - 2px);
          // background-color: $white;
          font-family: $font-family-main-bold;
          font-size: $font-size-smaller;
          color: $white;
          border-radius: 0 100px 100px 0;
          border: 2px solid $white;
          border-left: 0px;
          letter-spacing: 1px;
          text-align: center;
          &:hover {
            background-color: $blue-light-2;
            // color: $white;
          }
        }
