.o-social-link {
  @include size(46px);
  display: inline-block;
  background-color: $blue-light-2;
  border: 2px solid $blue-light-1;
  border-radius: 100px;
  + .o-social-link {
    margin-left: 0.5rem;
  }
  &:hover {
      background-color: rgba(39, 40, 41, .9);
  }
}

  .o-social-link--fb {
    background: $blue-light-2 url('/imgs/icon-social-fb_2x.png') center / 20px no-repeat;
  }

  .o-social-link--twitter {
    background: $blue-light-2 url('/imgs/icon-social-twitter_2x.png') center / 20px no-repeat;
  }

  .o-social-link--instagram {
    background: $blue-light-2 url('/imgs/icon-social-instagram_2x.png') center / 20px no-repeat;
  }

  .o-social-link--telegram {
    background: $blue-light-2 url('/imgs/icon-social-telegram_2x.png') center / 20px no-repeat;
  }

  .o-social-link--reddit {
    background: $blue-light-2 url('/imgs/icon-social-reddit_2x.png') center / 24px no-repeat;
  }
