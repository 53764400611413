.o-btn {
    display: inline-block;
    background-color: $blue-light-2;
    border: 2px solid $blue-light-1;
    color: $white;
    font-family: $font-family-main-bold;
    text-transform: uppercase;
    // letter-spacing: 1px;
    transition: all 0.3s ease-in-out;
    border-radius: 0;

    font-size: $font-size-smaller;
    padding: 0.9rem 2rem 0.7rem;

    @media (min-width: $screen-sm-min) {
        // font-size: $font-size-smaller;
        padding: 0.9rem 2rem;
    }

    &:hover {
        background-color: rgba(39, 40, 41, .9);
    }
}

    .o-btn--full-width {
        width: 100%;
        border: none;
        padding: 1.1rem 0;
    }

    .o-btn--pill {
      border-radius: 100px;
    }

    .o-btn--icon {
        background-color: $grey-light;
        border-color: $brand-color-2;
        color: #65481b;
        letter-spacing: 2px;
        padding: 20px 20px 20px 80px;
        background-repeat: no-repeat;
        margin: 0;
        @media (min-width: $screen-md-min) {
            padding: 20px 50px 20px 120px;
            font-size: $font-size-large;
        }
    }

    .o-btn--xxl {

    }

    .o-btn--primary {

    }

    .o-btn--transparent {
        border: 2px solid $white;
        color: white;
        background-color: transparent;
    }
