.o-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  @media (min-width: $screen-md-min) {
    bottom: 40px;
    right: 40px;
  }
  span {
    margin-bottom: 0.2rem;
  }
}

.o-widget__discord {
  display: block;
  @include size(60px);
  border-radius: 100px;
  background: url('/imgs/icon-discord_2x.png') center / contain no-repeat;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid $blue-light-1;
}
