.c-hero {
  .o-label-pill {
    margin: 1.7rem 0 0.5rem 0;
  }
  p {
    font-size: $font-size-default;
  }
  @media (min-width: $screen-sm-min) {
    padding-right: 0;
  }
}


  .c-hero__preregister {
    margin-top: 30px;
    // text-align: center;
  }

    .c-hero__preregister-android {
      display: inline-block;
      width: 240px;
      height: 72px;
      background: url('/imgs/preregister_google_play_2x.png') center / contain no-repeat;
    }


  .c-hero__download {
    margin-top: 30px;
    // text-align: center;
  }

    .c-hero__download-ios {
      display: inline-block;
      width: 215px;
      height: 72px;
      background: url('/imgs/badge-appstore_2x.png') center / contain no-repeat;
      @media (min-width: $screen-md-min) {
        margin-right: 1rem;
      }
    }

    .c-hero__download-android {
      display: inline-block;
      width: 240px;
      height: 72px;
      background: url('/imgs/badge-google-play_2x.png') center / contain no-repeat;
    }