* {
   box-sizing: border-box;
   &::selection {
     background: #adc5ff;
   }
}

a {
  font-family: $font-family-main-bold;
  color: $white;
  text-decoration: none;
  cursor: pointer; //todo: zasto ne radi??
  &:hover {
      cursor: pointer; //fix: sada radi
  }
  &[href^=tel] {
      color: inherit;
      text-decoration: none;
      font-family: inherit;
  }
  transition: all 0.25s ease-in-out;
}

button {
    cursor: pointer;
}

section {
    // padding: 3.125rem 0;
}

p {
    font-family: $font-family-main-medium;
    font-size: $font-size-small;
    line-height: 1.4;
    color: $white;
    // padding-bottom: 15px;
}

p + p {
    margin-top: 1rem;
}

p + .row {
    margin-top: 3.125rem;
}

b {
    color: $brand-color-1;
}

span {
  display: inline-block;
}

html, body {
    // height: 100%;
    max-width: 100%;
    font-family: $font-family-main-medium;
    font-size: 1em;

    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering:    optimizeLegibility;
    -ms-text-rendering:     optimizeLegibility;
    -o-text-rendering:      optimizeLegibility;
    text-rendering:         optimizeLegibility;
    font-smoothing:         antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing:    antialiased;
    -ms-font-smoothing:     antialiased;
    -o-font-smoothing:      antialiased;
    font-smoothing:         antialiased;


    @media (min-width: $screen-md-min) {
        font-size: 1em;
    }
    color: $white;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    // overflow: hidden;
}

body {
    // overflow-y: scroll;
    // -webkit-overflow-scrolling:touch;
    background-color: $blue-1;
}

ul {
    list-style: none;
}

img {
    @include size(100% auto);
}

input, textarea {
    -webkit-appearance: none;
    outline: none;
    border: none;

    &:hover {
        cursor: text;
    }
    &[type='submit'] {
        &:hover {
            cursor: pointer;
        }
    }
}
