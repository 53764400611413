/*-----------------------------------------------------------------------------/
 *  Project: Indekeu website
 *  ===========================================
 *
 *
 *  TABLE OF CONTENTS
 *
 *  #SETTINGS................Global variables, config switches.
 *  #TOOLS...................Default mixins and functions.
 *  #GENERIC.................Ground-zero styles (resets, box-sizing).
 *  #BASE....................Unclassed HTML elements (type selectors).
 *  #OBJECTS.................Cosmetic-free design patterns.
 *  #COMPONENTS..............Designed components, chunks of UI.
 *  #TRUMPS..................Helpers and overrides.
 *
 *
 *
 *  ATTENTION:
 *  The following parts are imported in the right order
 *  DO NOT CHANGE THE ORDER OF THE IMPORTS IN THIS FILE!!!!!
 *
 *  HINT:
 *  Each of these parts live in their own directory. Further explanation is
 *  provided in the dedicated folders.
 *
 *  *** Responsive Grid system & (maybe) Navbar code used from bootstrap-sass v3.0
 *  *** We are also using bourbon mixins: http://bourbon.io
 *
/-----------------------------------------------------------------------------*/



// #SETTINGS
// ===========================================
@import "1-Settings/s-global";
@import "1-Settings/s-bootstrap";
@import "1-Settings/s-slick-carousel";


// #TOOLS
// ===========================================
@import "../bower_components/bourbon/app/assets/stylesheets/bourbon";
@import "2-Tools/_t-bootstrap.mixins.clearfix.scss";
@import "2-Tools/_t-bootstrap.mixins.grid-framework.scss";
@import "2-Tools/_t-bootstrap.mixins.grid.scss";
@import "2-Tools/_t-bootstrap.mixins.nav-vertical-align.scss";
@import "2-Tools/_t-bootstrap.mixins.responsive-visibility.scss";
@import "2-Tools/_t-mixins.scss";
@import "2-Tools/_t-parallax.scss";


// #GENERIC
// ===========================================
@import "3-Generic/_g-fonts.scss";
@import "3-Generic/_g-normalize.scss";


// #BASE
// ===========================================
@import "4-Base/_b-headings.scss";
@import "4-Base/_b-page.scss";


// #OBJECTS
// ===========================================
@import "slick-carousel/slick/slick.scss";
@import "5-Objects/_o-bgs.scss";
@import "5-Objects/_o-bootstrap.grid.scss";
@import "5-Objects/_o-buttons.scss";
@import "5-Objects/_o-imgs.scss";
@import "5-Objects/_o-labels.scss";
@import "5-Objects/_o-logo.scss";
@import "5-Objects/_o-nav-overlay.scss";
@import "5-Objects/_o-navbar-toggle.scss";
@import "5-Objects/_o-sections.scss";
@import "5-Objects/_o-social-links.scss";
@import "5-Objects/_o-subscribe-widget.scss";
@import "5-Objects/_o-subscribe.scss";
@import "5-Objects/_o-widget.scss";


// #COMPONENTS
// ===========================================
@import "slick-carousel/slick/slick-theme.scss";
@import "plyr/dist/plyr";
@import "6-Components/_c-about-game.scss";
@import "6-Components/_c-community.scss";
@import "6-Components/_c-faq.scss";
@import "6-Components/_c-footer.scss";
@import "6-Components/_c-header.scss";
@import "6-Components/_c-hero.scss";
@import "6-Components/_c-hora-token.scss";
@import "6-Components/_c-notice.scss";
@import "6-Components/_c-privacy-policy.scss";
@import "6-Components/_c-slider.scss";
@import "6-Components/_c-terms-of-service.scss";
@import "6-Components/_c-under-construction.scss";


// #TRUMPS
// ===========================================
@import "7-Trumps/_t-bootstrap-utilities.scss";
@import "7-Trumps/_t-free-space.scss";
@import "7-Trumps/_t-vertical-align.scss";

.table {
  border: 1px solid white;
  td, th {
    padding: 5px;
    border: 1px solid white;
  }
  tr {
    cursor: pointer;
  }
}

.link {
  cursor: pointer;
}