.o-section {
  border-bottom: 2px solid $blue-light-1;
  .o-section-inner {
    height: 100%;
    border-left: 2px solid $blue-light-1;
    border-right: 2px solid $blue-light-1;
  }
}

  .o-section--mobile-center {
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }

  .o-section--overlap {
    @media (min-width: $screen-sm-min) {
      margin-top: -5rem;
    }
  }

    .o-section-inner {

    }

      .o-section-inner--m {
        padding: 4rem 1rem;
        @media (min-width: $screen-sm-min) {
          padding: 4rem 1rem;
        }
      }

      .o-section-inner--xl {
        padding: 4rem 1rem;
        @media (min-width: $screen-sm-min) {
          padding: 12rem 1rem;
        }
      }




.o-section-mobile-separator {
  height: 2rem;
  @media (min-width: $screen-sm-min) {
    display: none;
  }
}
