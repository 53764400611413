.c-community {

}


  .c-community-heading {
    padding: 3rem 0;
    background-color: $blue-light-2;
    border-bottom: 2px solid $blue-light-1;
  }

    .c-community-heading__content {
      padding: 0 1rem;
      span {
        display: block;
        margin-bottom: 0.3rem;
      }
      h2 {
        margin-bottom: 0;
      }
      @media (min-width: $screen-sm-min) {
        h2 {
          line-height: 0.9;
        }
      }
    }





  .c-community-networks {

  }

    .c-community-networks-item {
      padding: 3rem 1rem;
      h6 {
        font-family: $font-family-main-bold;
        margin-bottom: 1rem;
      }
      p {
        line-height: 1.4;
        max-width: 350px;
      }
      .o-btn {
        margin: 3rem 0 0;
      }
      + .c-community-networks-item {
        border-top: 2px solid $blue-light-1;
      }
      @media (min-width: $screen-sm-min) {
        padding: 3rem;
      }
      @media (min-width: $screen-md-min) {
        min-height: 292px;
        + .c-community-networks-item {
          border-top: 0;
          border-left: 2px solid $blue-light-1;
        }
      }
    }


      .c-community-networks-item__social-btns {
        margin: 3rem 0 0;
      }
