//
//  https://css-tricks.com/centering-css-complete-guide/
//

.vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


.t-vertical-align--flexbox {
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  @media (min-width: $screen-md-min) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}
