.o-nav-overlay {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

  .o-nav-overlay--visible {
    visibility: visible;
    .o-nav-overlay-sidebar {
      transform: translateX(0);
    }
    .o-nav-overlay-backdrop {
      opacity: 1;
    }
  }

    .o-nav-overlay-backdrop {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      transition: all 0.25s ease-in-out;
      opacity: 0;
      z-index: 10100;
    }

    .o-nav-overlay-sidebar {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      max-width: 300px;
      height: 100%;
      background-color: $blue-1;
      padding: 2rem 3rem;
      transition: all 0.5s ease-in-out;
      transform: translateX(100%);
      z-index: 10200;
      @media (min-width: $screen-sm-min) {
        max-width: 380px;
      }
    }

      .o-nav-overlay-sidebar__close {
        width: 22px;
        height: 22px;
        margin-bottom: 3rem;
        transition: all 0.25s ease-in-out;
        cursor: pointer;
        &:hover {
          opacity: 0.4;
        }
        @media (min-height: 600px) {
          margin-bottom: 6rem;
        }
      }

      .o-nav-overlay-sidebar__logo {
        display: block;
        width: 100%;
        height: 44px;
        margin-bottom: 3rem;
        transition: all 0.25s ease-in-out;
        cursor: pointer;
        &:hover {
          opacity: 0.4;
        }
        @media (min-height: 600px) {
          margin-bottom: 6rem;
        }
      }

      .o-nav-overlay-sidebar__main-nav {
        padding: 1.5rem 0;
        margin-bottom: 2rem;
        li {
          a {
            font-family: $font-family-main-bold;
            transition: all 0.25s ease-in-out;
            &:hover, &.active {
              color: #ccc;
            }
          }
          + li {
            margin-top: 1rem;
          }
        }
      }

      .o-nav-overlay-sidebar__second-nav {
        padding: 0;
        li {
          a {
            font-family: $font-family-main-bold;
            font-size: $font-size-small;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: all 0.25s ease-in-out;
            &:hover, &.active {
              color: #ccc;
            }
          }
          + li {
            margin-top: 0.5rem;
          }
        }
      }
