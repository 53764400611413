.c-header {
  border-bottom: 2px solid $blue-light-1;
}


  .c-header__inner {
    display: flex;
    border-left: 2px solid $blue-light-1;
    border-right: 2px solid $blue-light-1;
    padding: 0.9rem 1rem 0.5rem;
    @media (min-width: $screen-sm-min) {
      padding: 2.5rem 1rem 0.5rem;
    }
  }

    .c-header-links {
      display: table-cell;
      width: calc(100%);
      // border: 1px solid yellow;
      vertical-align: middle;
      .o-logo {
        display: table-cell;
      }
    }

      .c-header-nav {
        display: table-cell;
        // border: 1px solid red;
        vertical-align: middle;
        padding-top: 5px;
      }

        .c-header-nav__item {
          display: inline-block;
          margin-left: 2rem;
          font-size: $font-size-smaller;
          // + .c-header-nav__item {
          //    margin-left: 20px;
          // }
          @media (min-width: $screen-md-min) {
            font-size: $font-size-small;
          }
        }

          .c-header-nav__link {
            text-transform: uppercase;
            &:hover {
              opacity: 0.7;
            }
          }



    .c-header-social {
      display: inline-block;
      // border: 1px solid red;
      min-width: 269px;
      height: 60px;
      display: table-cell;
      vertical-align: middle;
      padding-top: 12px;
      @media (max-width: $screen-sm-max) {
        min-width: 200px;
        .o-social-link {
          width: 32px;
          height: 32px;
          background-size: 14px;
        }
      }

      display: flex;
      align-items: center;
    }

      .c-header-social__item {
        @include size(32px);
        display: inline-block;
        border-radius: 100px;
        float: right;
        border: 2px solid $white;
        margin-left: 10px;
        &:hover {
          opacity: 0.7;
        }
        @media (min-width: $screen-md-min) {
          @include size(40px);
        }
      }
