.o-navbar-toggle {
    // position: relative;
    // float: right;
    // margin-right: $navbar-padding-horizontal;
    padding: 1px;
    // @include navbar-vertical-align(34px);
    background-color: transparent;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    border-radius: $border-radius-base;

    transition: all 0.25s ease-in-out;

    // We remove the `outline` here, but later compensate by attaching `:hover`
    // styles to `:focus`.
    &:focus {
        outline: 0;
    }

    // Bars
    .o-icon-bar {
        display: block;
        width: 25px;
        height: 2px;
        background-color: $white;
    }
    .o-icon-bar + .o-icon-bar {
        margin-top: 6px;
    }

    // margin-right: 20px;

    //@media (min-width: $grid-float-breakpoint) {
    //    display: none;
    //}
}
