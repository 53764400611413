//
//  Slick carousel settings
//
$slick-font-path: "./fonts/" !default;
$slick-font-family: "genericons" !default;
$slick-loader-path: "/imgs/" !default;
$slick-arrow-color: $white;
$slick-dot-color: $blue-light-2 !default;
$slick-dot-color-active: $white !default;
// $slick-prev-character: "\00AB";
// $slick-next-character: "\00BB";
$slick-dot-character: "\2022" !default;
$slick-dot-size: 45px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;
