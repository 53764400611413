.c-about-game {

}

  .c-about-game-text {
    padding: 2rem 1rem;
    @media (min-width: $screen-sm-min) {
      padding: 2rem 3rem 2rem 2rem;
    }
    h6 {
      margin: 3rem 0 1rem;
      // font-family: $font-family-main-bold;
    }
  }

  .c-about-game-features {
    background-color: $blue-light-2;
    // min-height: 800px;
    padding: 2rem 1rem;
    border-top: 2px solid $blue-light-1;
    @media (min-width: $screen-md-min) {
      border-left: 2px solid $blue-light-1;
      border-top: 0;
      padding: 2rem;
    }
  }


    .c-about-game-features-item {
      display: flex;
      align-items: center;
      + .c-about-game-features-item {
        margin-top: 1rem;
      }
    }

      .c-about-game-features-item__content {
        margin-left: 1rem;
      }

        .c-about-game-features-item__heading {
          display: block;
          font-family: $font-family-main-bold;
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }

        .c-about-game-features-item__text {
          line-height: 1.3;
        }


        .c-about-game-features-item__icon {
          min-width: 120px;
          height: 100px;
          border: 2px solid $blue-light-1;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }

          .c-about-game-features-item__icon--crypto {
            background-image: url('/imgs/icon-games-feature-crypto.png');
          }

          .c-about-game-features-item__icon--cubes {
            background-image: url('/imgs/icon-games-feature-cubes.png');
          }

          .c-about-game-features-item__icon--miners {
            background-image: url('/imgs/icon-games-feature-miners.png');
          }

          .c-about-game-features-item__icon--money {
            background-image: url('/imgs/icon-games-feature-money.png');
          }

          .c-about-game-features-item__icon--pocket {
            background-image: url('/imgs/icon-games-feature-pocket.png');
          }

          .c-about-game-features-item__icon--up {
            background-image: url('/imgs/icon-games-feature-up.png');
          }
