.c-footer {
  position: relative;
}

  .c-footer-logo {
    height: 250px;
    background-color: $blue-light-2;
    border-right: 2px solid $blue-light-1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .c-footer-logo__content {
      color: $white;
      text-transform: uppercase;
      display: inline-block;
      text-align: center;
      img {
        display: inline-block;
        width: 76px;
        height: 113px;
      }
      span {
        display: block;
        margin-top: 1rem;
        font-size: $font-size-default;
      }
    }

  .c-footer-subscribe {
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
  }

    .c-footer-subscribe__content {
      max-width: 400px;
      h6 {
        margin-bottom: 1rem;
      }
      p {
        line-height: 1.4;
      }
    }

    .c-footer-links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 250px;
      li {
        + li {
          margin-top: 1rem;
        }
      }
      @media (min-width: $screen-md-min) {
        align-items: flex-start;
      }
    }

  .c-footer-trading-manager {
    position: absolute;
    right: 60px;
    bottom: 20px;
    width: 143px;
    height: 332px;
    background: url('/imgs/trading-manager-2_2x.png') center / contain no-repeat;
  }
