.c-terms-of-service {
    h1, h2, h3, h4, h5, h6 {
        margin-top: 2rem;
        font-family: $font-family-main-bold;
        font-weight: normal;
        letter-spacing: 0;
    }
    h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 2.5rem;
    }
    h3 {
        font-size: 1.6rem;
    }
    h4 {
        font-size: 1.2rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 1rem;
    }
    p {
        p + {

        }
    }
    ul {
        list-style-type: circle;
        li {
            // list-style-position: inside;
            margin-left: 50px;
        }
    }
    ol {
        li {
            margin-left: 50px;
        }
    }
    table {
        width: 100%;
        border: 1px solid #fff;
        tr {
            th {
                border: 1px solid #fff;
                padding: 20px 30px;
                max-width: 33%;
            }
            td {
                border: 1px solid #fff;
                padding: 20px 30px;
                max-width: 33%;
            }
        }
    }
}