$h-base-size: 3.125rem;
$h-reduce-size: 1.2;

$h-reduce-mobile-size: 1.5;

$h1-size: $h-base-size;
$h2-size: $h-base-size / $h-reduce-size;
$h3-size: $h-base-size / $h-reduce-size / $h-reduce-size;
$h4-size: $h-base-size / $h-reduce-size / $h-reduce-size / $h-reduce-size;
$h5-size: $h-base-size / $h-reduce-size / $h-reduce-size / $h-reduce-size / $h-reduce-size;
$h6-size: $h-base-size / $h-reduce-size / $h-reduce-size / $h-reduce-size / $h-reduce-size / $h-reduce-size;

$h-margin-bottom: 1.4rem;


h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  font-family: $font-family-main-bold;
  font-weight: normal;
  // text-transform: uppercase;
  letter-spacing: 1px;
}

h4, h5, h6 {
  font-family: $font-family-main-medium;
}

h1 {
  font-size: $h1-size / $h-reduce-mobile-size;
  margin-bottom: $h-margin-bottom / $h-reduce-size;
  @media (min-width: $screen-sm-min) {
    font-size: $h1-size;
  }
}
h2 {
  font-size: $h2-size / $h-reduce-mobile-size;
  margin-bottom: $h-margin-bottom / $h-reduce-size / $h-reduce-size;
  @media (min-width: $screen-sm-min) {
    font-size: $h2-size;
  }
}
h3 {
  font-size: $h3-size / $h-reduce-mobile-size;
  margin-bottom: $h-margin-bottom / $h-reduce-size / $h-reduce-size / $h-reduce-size;
  @media (min-width: $screen-sm-min) {
    font-size: $h3-size;
  }
}
h4 {
  font-size: $h4-size / $h-reduce-mobile-size;
  margin-bottom: $h-margin-bottom / $h-reduce-size / $h-reduce-size / $h-reduce-size / $h-reduce-size;
  @media (min-width: $screen-sm-min) {
    font-size: $h4-size;
  }
}
h5 {
  font-size: $h5-size / $h-reduce-mobile-size;
  margin-bottom: $h-margin-bottom / $h-reduce-size / $h-reduce-size / $h-reduce-size / $h-reduce-size / $h-reduce-size;
  @media (min-width: $screen-sm-min) {
    font-size: $h5-size;
  }
}
h6 {
  font-size: $h6-size / $h-reduce-mobile-size;
  margin-bottom: $h-margin-bottom / $h-reduce-size / $h-reduce-size / $h-reduce-size / $h-reduce-size / $h-reduce-size / $h-reduce-size;
  @media (min-width: $screen-sm-min) {
    font-size: $h6-size;
  }
  letter-spacing: 0;
}
