.c-hora-token {

}

  .c-hora-token-graph {
    background-color: #285BC2;
    @media (min-width: $screen-md-min) {
      padding: 2rem 10rem;
    }
  }

  .c-hora-token-text {
    padding: 2rem 1rem;
    background-color: $blue-1;
    // border-top: 2px solid $blue-light-1;
    border-bottom: 2px solid $blue-light-1;
    span {
      display: block;
      text-transform: uppercase;
      margin-bottom: 0.3rem;
      // font-size: $font-size-small;
    }
    h2 {
      margin-bottom: 1.5rem;
    }
    @media (min-width: $screen-sm-min) {
      padding: 4rem 2rem;
      // border-top: 0;
    }
  }
